import React from "react"
import { useDetectDevice } from "../../../../../lib/useDetectDevice"
import { Column, Wrapper } from "./SalesArgument.style"

interface Props {
  data: any
  className: string
}

export function SalesArgument({ data, className }: Props) {
  const isMobile = useDetectDevice()
  const classNames = (className && className) + " " + (isMobile && "mobile")

  return (
    <Wrapper className={classNames}>
      <h2>{data.number}</h2>
      <Column className={classNames}>
        <h3>{data.title}</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: data.content.data.childMarkdownRemark.html,
          }}
        />
      </Column>
    </Wrapper>
  )
}
