import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { useDetectDevice } from "../../../../lib/useDetectDevice"
import {
  BackgroundImage,
  Column,
  DivHtml,
  Flex,
  Wrapper,
} from "./BlockSalesArgument.style"
import { PartnerBrands } from "./PartnerBrands/PartnerBrands"
import { SalesArgument } from "./SalesArgument/SalesArgument"

interface Props {
  data: any
  className: string
}

export function BlockSalesArgument({ data, className }: Props) {
  const isMobile = useDetectDevice()
  const classNames = (className && className) + " " + (isMobile && "mobile")

  return (
    <Wrapper className={classNames}>
      <Flex className={classNames}>
        <Column className={classNames}>
          <DivHtml
            className={classNames}
            dangerouslySetInnerHTML={{
              __html: data.title.data.childMarkdownRemark.html,
            }}
          />
          {data.atouts.map(atout => {
            return <SalesArgument data={atout} className={""} />
          })}
        </Column>
        <Column className={classNames}>
          <PartnerBrands data={data.marques} className={classNames} background={data.background} />
          <BackgroundImage
            data={data.background}
            className={classNames}
            objectFit="cover"
          />
        </Column>
      </Flex>
    </Wrapper>
  )
}
