import styled from "styled-components"

export const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  width: fit;
  display: flex;
  gap: 32px;
  margin-bottom: 58px;
  h2{
    color: #E9E9E9;
    font: normal normal normal 90px/70px Times New Roman;
    margin: 0px;
  }
  h3{
    color: #C4AB88;
    font-size: 22px;
    margin: 0px;
  }
  p{
    font: normal normal normal 15px/24px Poppins;
    font-size: 15px;
    color: #2B3944;
    margin: 0px;
  }
}
  &.mobile{
    gap: 18px;
    width: 88%;
    margin: auto;
    padding-bottom: 54px;
    h3{
      font-size: 18px;
    }
  }
`

export const Column = styled.div`
  width: auto;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 14px;

  &.mobile{
    width: auto;
    min-width: auto;
    gap: 10px;
  }
`
