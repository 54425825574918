import styled from "styled-components"
import { ImageWithLinkOrNot } from "../../../../common/ImageWithLinkOrNot"
import { Link as LinkBase } from "../../../../common/Link"

export const Wrapper = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  position: relative;
  height: inherit;
  min-height: 804px;
  &.mobile {
    min-height: 850px;
    gap: 0;
    height: auto;
    justify-content: space-around;
  }
`

export const Grid = styled.div`
  max-width: 700px;
  width: auto;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &.mobile {
    padding-top: 75px;
    width: 90%;
    margin: auto;
    gap: 10px;
    & button {
      margin-bottom: 25px;
    }
  }
`

export const Logo = styled(ImageWithLinkOrNot)`
  width: 200px;
  height: 120px;
  object-fit: contain;
  align-self: center;
  cursor: pointer;
  &.mobile {
    width: 160px;
    height: auto;
  }
`

export const Link = styled(LinkBase)`
  font-family: "Cormorant Garamond";
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  font-size: 20px;
  color: #2b3944;
  &.mobile {
    font-size: 24px;
    margin-bottom: 100px;
  }
`

export const BackgroundImage = styled(ImageWithLinkOrNot)`
  width: auto;
  object-fit: cover;
  align-self: center;
  opacity: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  &.mobile {
    opacity: 0.1;
    width: 10vw;
    min-width: 100vw;
    height: 100%;
    object-fit: cover;
  }
`
