import styled from "styled-components"
import { ImageWithLinkOrNot } from "./../../common/ImageWithLinkOrNot"
import { Slider } from "../../common/Slider"
import { TextOverText } from "../../common/TextOverText"
import { BigText } from "../../common/TextOverText/TextOverText.style"
import { Title as TitleBase } from "../../common/Title"

export const Wrapper = styled.div`
  box-sizing: border-box;
`

export const ImageCollectionMobile = styled(ImageWithLinkOrNot)`
  height: 80%;
  max-width: 460px;
  height: 460px;
  margin-top: 2em;
  margin-left: auto;
  margin-right: auto;
`

export const MobileCard = styled(ImageWithLinkOrNot)`
  height: 295px;
  width: 375px;
  margin: 0;
`

export const SliderMarque = styled(Slider)`
  .slick-list {
    background-color: black !important;
  }
`

export const WraperSliderMarque = styled.div`
  width: 100vw;
  padding-bottom: 5em;
`

export const Flex = styled.div`
  max-width: 1640px;
  width: 90%;
  margin: auto;
  display: flex;
  gap: 59px;
  justify-content: space-between;
  margin-bottom: 90px;
`

export const BlogComponent = styled.div`
  max-width: 1640px;
  width: 90%;
  margin: auto;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 90px;
  overflow: auto;
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
`

export const Post = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  gap: 15px;
  justify-content: center;
  `

export const ImagePost = styled(ImageWithLinkOrNot)`
    height: 300px;
    width: 450px;
    margin: 0;
    picture > img {
      object-fit: cover !important;
      border: 50px red;
    }
  `

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
  margin-bottom: 68px;
`

export const Card = styled(ImageWithLinkOrNot)`
  max-height: 622px;
  max-width: 789px;
  min-width: 400px;
  margin: 0;
`
export const SliderAcceuil = styled(Slider)`
  object-fit: cover;
`

export const TextOverTextS = styled(TextOverText)`
  margin-top: 5em;
  margin-bottom: 2em;
  ${BigText} {
    letter-spacing: -1.5px;
    color: #213544;
    opacity: 1;
    font: normal normal 600 60px/22px Cormorant Garamond;
  }
`

export const TextOverTextM = styled(TextOverText)`
  margin-top: 3em;
`

export const WraperSliderMobile = styled.div``

export const Title = styled(TitleBase)`
  h1 {
    margin: 0;
  }
`
