import React, { } from "react"

import { ProductListItemView } from "./ProductListItem.view"
import { usePictoQuery } from "./ProductListItem.query"
import getBrandFromSaleor from "../../../helper/getBrandFromSaleor"
import getBrandFromZoho from "../../../helper/getBrandFromZoho"


interface Props {
  className?: string
  id: string
  name: string
  slug: string
  thumbnail: Record<string, string>
  pricing: Record<string, any>
  isAvailable: boolean
  availableForPurchase: string
  isAvailableForPurchase: boolean
  metadata: Record<string, string>
  variants: Record<string, any>
  products: any
  attributes: any
}

export function ProductListItemContainer(props: Props) {

  const data = usePictoQuery()

  const brandName = getBrandFromSaleor(props.attributes)

  return (
    <ProductListItemView
      {...props}
      //  active={true}
      variantsMetadata={props.variants}
      brandName={brandName}
      data={data}
    // isBack={isBack}
    />
  )
}
