import { HomepageView as Homepage } from "./../components/specific/PageHome/Homepage.view"
import { graphql } from "gatsby"

export default Homepage

export const IndexQuery = graphql`
  query IndexQuery {
    strapiGmMessageBox {
      Text
      BackgroundColor
      FontColor
    }
    strapiGmHomePage {
      CarouselBlog {
        Title
        pages_cms {
          Title
          slug
          Text {
            data {
              Text
            }
          }
          Image {
            url
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 500
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
      CarouselTendance {
        ImageLienTexte {
          Image {
            url
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1024
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          Link
          Text
          id
        }
        Titre
        id
      }
      CarouselMarketing {
        id
        ImageLien {
          Link
          id
          Image {
            url
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
      selection {
        titre
        collections_saleor {
          saleor_id
        }
      }
      affaires {
        titre
        collections_saleor {
          saleor_id
        }
      }
      Atouts {
        title {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
        background {
          name
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(sizes: "100vw")
            }
          }
        }
        atouts {
          number
          title
          content {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
        }
        marques {
          Link
          Image {
            name
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
