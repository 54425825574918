import { useStaticQuery, graphql } from "gatsby"

export const usePictoQuery = () => {
  const data = useStaticQuery(
    graphql`
    query PictoQuery {
      strapiGmPicto {
        Promo10 {
          url
          alternativeText
        }
        Promo20 {
          url
          alternativeText
        }
        Promo30 {
          url
          alternativeText
        }
        Promo40 {
          url
          alternativeText
        }
        Promo50 {
          url
          alternativeText
        }
        Nouveaute {
          url
          alternativeText
        }
      }
    }
    `
  )
  return data
}
