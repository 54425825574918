import styled from "styled-components"
import { ImageWithLinkOrNot } from "../../../common/ImageWithLinkOrNot"

export const Wrapper = styled.div`
  min-height: 804px;
  height: fit-content;
  position: relative;
  box-shadow: inset 0px 0px 15px 5px #E9E9E9;
  margin-bottom: 3em;
  &.mobile{
    height: auto;
  
  }
`
export const DivHtml = styled.div`
  font-size: 60px;
  margin-left: 77px;
  width: 420px;
  height: 240px;
  display: flex;

  align-items: center;
  font-family: "Cormorant Garamond";
  &.mobile{
    margin: auto;
    width: 280px;
    height: auto;
    text-align: center;
    font-size: 40px;
  }
`

export const BackgroundImage = styled(ImageWithLinkOrNot)`
  width: auto;
  object-fit: cover;
  align-self: center;
  opacity: 0.1;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  &.mobile{
    opacity: 0;
  }
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-evenly;
  z-index: 2;
  padding-top: 2em;
  &.mobile{
    display: flex;
    flex-direction: column;
  }
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.mobile{
    display: flex;
    flex-direction: column;
  }
`
