import { navigate } from "gatsby"
import React from "react"
import { useDetectDevice } from "../../../../../lib/useDetectDevice"
import { enumFrom } from "../../../../common/ImageWithLinkOrNot"
import { BackgroundImage, Column, Grid, Link, Logo, Wrapper } from "./PartnerBrands.style"

interface Props {
  data: any
  background: any
  className: string
}

export function PartnerBrands({ data, className, background }: Props) {
  const isMobile = useDetectDevice()
  const classNames = (className && className) + " " + (isMobile && "mobile")

  return (
    <Wrapper className={classNames}>
      <Grid className={classNames}>
        {data.map(marque => {
          return (
            <Logo
              onClick={() => navigate(marque.Link)}
              data={marque.Image}
              from={enumFrom.STRAPI}
              className={classNames}
            />
          )
        })}
      </Grid>
      <Link className={classNames} link="/qui-sommes-nous/">Découvrir Grandes Marques</Link>
      <BackgroundImage
            data={background}
            className={classNames}
            objectFit="cover"
          />
    </Wrapper>
  )
}
